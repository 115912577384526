<template>
<div class="comp-container">
test_03
</div>
</template>
<script>
export default {
//props: {
//},
data() {
return {

}
},
created() {},
mounted() {},
//watch: {},
computed: {},
methods: {
    test(){
        alert("aaaaa")
    },
},
components: {
},
}
</script>
<style scoped>
.comp-container{
    background-color: skyblue;
    padding: 20px;
    text-align: center;
}
</style>